window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');
require('cloud9carousel');

let carousel = $('#carousel');
let carouselButton = $('#carousel-button');

if (carousel.length) {
    carousel.Cloud9Carousel({
        autoPlay: true,
        bringToFront: true,
        onRendered: function (carousel) {
            carouselButton.text(carousel.nearestItem().element.alt);
        }
    });
}
